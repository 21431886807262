import axios from 'axios';
import { set as storageSet } from '@/plugins/storage';
import moment from 'moment';
import { http } from '@/plugins/http';
import { setInUse, unsetInUse } from '@/app/appointment/api';

const IN_USE = {
  intervalId: 0,
  ttl: 60,
};

export function upsert({ commit, state }, data) {
  commit('Appointment.UPSERT', data);

  if (state.show) {
    const found = state.items.find(({ key }) => key === data.key);
    if (found && found.key === state.form.key) {
      commit('Appointment.SHOW_APPOINTMENT_MODAL', found);
    }
  }
}

export async function openAppointmentModal({ commit, state }, data) {
  commit('Appointment.SHOW_APPOINTMENT_MODAL', data);

  if (state.form.key && !state.form.id) {
    const inUseFn = () => {
      setInUse(state.form.key, IN_USE.ttl)
        .then((res) => {
          commit('Appointment.SET_APPOINTMENT_IN_USE_BY', res);
        })
        .catch(() => {});
    };

    inUseFn();
    IN_USE.intervalId = setInterval(inUseFn, IN_USE.ttl * 1000);
  }
}

export async function closeAppointmentModal({ commit, state }) {
  clearInterval(IN_USE.intervalId);

  if (state.form.key) {
    await unsetInUse(state.form.key);
    IN_USE.intervalId = 0;
  }

  commit('Appointment.HIDE_APPOINTMENT_MODAL');
}

export async function loadFilters({ state }) {
  const params = {
    limit: 0,
    active: true,
  };

  http.get('/schedule-expenses', { params })
    .catch(() => ({ items: [] }))
    .then(({ data }) => {
      state.filter.expenses = data.items;
    });

  http.get('/call-center/insurance-plans', { params })
    .catch(() => ({ items: [] }))
    .then(({ data }) => {
      state.filter.insurances = data.items;
    });

  http.get('/call-center/schedules', {
    params: { ...params, type: 'elective' },
  })
    .catch(() => ({ items: [] }))
    .then(({ data }) => {
      state.filter.schedules = data.items;
    });
}

function makeFilterParams(state) {
  const params = {
    type: 'elective',
    date: state.filter.date,
  };

  if (state.filter.expenseId) {
    const [expenseId] = state.filter.expenseId.split(':');
    params.expenseId = expenseId;
  }

  if (state.filter.insuranceId) {
    params.insuranceId = state.filter.insuranceId;
  }

  if (state.filter.scheduleId && state.filter.scheduleId !== 'ALL') {
    params.scheduleId = state.filter.scheduleId;
  }

  if (state.filter.professionalId) {
    params.professionalId = state.filter.professionalId;
  }

  return params;
}

let calendarAbortController;
export async function loadCalendar({
  commit,
  dispatch,
  state,
  rootState,
}, availableDays = false) {
  if (calendarAbortController && state.loading) {
    calendarAbortController.abort();
  }

  const params = makeFilterParams(state);

  commit('Appointment.SET_LOADING', true);

  const { user } = rootState.auth;
  const insurancePlanIds = user.insurancePlanIds || [];

  calendarAbortController = new AbortController();

  let items = [];
  let schedules = [];
  const insurances = [];
  const scheduleIds = [];

  http
    .get('/calendar', {
      params,
      signal: calendarAbortController.signal,
    })
    .then(({ data }) => {
      data.insurances.filter(({ plan }) => insurancePlanIds.includes(plan.id))
        .forEach((item) => {
          insurances.push(item);
          item.schedules.forEach((scheduleId) => {
            scheduleIds.push(scheduleId);
          });
        });

      schedules = data.schedules.filter(item => scheduleIds.includes(item.id));
      items = data.items
        .filter(item => scheduleIds.includes(item.scheduleId)
          && (
            !item.id
            || (item.id && item.insurance && insurancePlanIds.includes(item.insurance.plan.id))
          ));

      commit('Appointment.SET_SCHEDULES', schedules);
      commit('Appointment.SET_PROFESSIONALS', data.professionals);
      commit('Appointment.SET_INSURANCES', insurances);
      commit('Appointment.SET_ITEMS', items);
      if (availableDays) {
        dispatch('loadAvailableDays');
      }
    })
    .catch((e) => {
      if (axios.isCancel(e)) {
        return;
      }
      commit('Appointment.SET_SCHEDULES', []);
      commit('Appointment.SET_PROFESSIONALS', []);
      commit('Appointment.SET_INSURANCES', []);
      commit('Appointment.SET_ITEMS', []);
      state.available.days = [];
    })
    .finally(() => {
      commit('Appointment.SET_LOADING', false);
    });

  await storageSet('appointmentFilters', params);
}

export async function loadAvailableDays({ state }) {
  const params = makeFilterParams(state);
  delete params.date;

  let canLoad = ['scheduleId', 'professionalId']
    .some(v => (v in params && params[v]));

  if (canLoad) {
    const date = moment(state.currentMonth || state.filter.date);
    const today = moment();

    if (date.isBefore(today, 'month')) {
      canLoad = false;
    } else {
      params.startDate = date.isSame(today, 'month')
        ? today.startOf('day')
        : date.startOf('month');
      params.endDate = date.clone().endOf('month');
    }
  }

  if (canLoad) {
    params.startDate = params.startDate.format('YYYY-MM-DD');
    params.endDate = params.endDate.format('YYYY-MM-DD');

    http.get('/calendar/available-days', { params })
      .then(({ data }) => {
        state.available.days = data;
      })
      .catch(() => {
        state.available.days = [];
      });
  } else {
    state.available.days = [];
  }
}
