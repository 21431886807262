import moment from 'moment';

function blankForm() {
  return {
    id: null,
    scheduleId: null,
    schedule: { id: null, notes: '' },
    type: '',
    startDate: '',
    endDate: '',
    patient: {
      canSave: false,
      image: {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      },
      id: null,
      name: '',
      birthDate: '',
      gender: '',
      identity: {
        type: '',
        value: '',
      },
      phone: '',
      cellphone: '',
      email: '',
      insurance: {
        id: '',
        name: '',
        record: '',
        validity: '',
        plan: {
          id: '',
          name: '',
        },
      },
      address: {
        id: '',
        type: '',
        postalCode: '',
        addressLine1: '',
        addressLine2: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      imageUrl: '',
      alert: '',
      notes: '',
      cns: '',
      motherName: '',
      fatherName: '',
    },
    insurance: {
      id: '',
      name: '',
      record: '',
      validity: '',
      type: '',
      plan: {
        id: '',
        name: '',
        settings: {
          tiss: {
            enabled: false,
            consultationInSadt: false,
          },
          record: {
            required: false,
            requiredValidity: false,
            digits: {
              enabled: false,
              min: 0,
              max: 0,
            },
          },
        },
      },
    },
    professional: {
      id: '',
      name: '',
      council: {
        record: '',
        name: '',
        state: '',
      },
      specialty: '',
    },
    expenses: [],
    status: 'scheduled',
    notes: '',
    arrivedAt: null,
    key: null,
    slot: false,
    reserves: 1,
    meta: null,
    indicated: {
      id: '',
      entityId: '',
      name: '',
      type: '',
      council: {
        name: 'CRM',
        state: 'PR',
        record: '',
      },
    },
    inUseBy: null,
  };
}

function blankFilter() {
  return {
    date: moment().format('YYYY-MM-DD'),
    expenses: [],
    expenseId: '',
    insurances: [],
    insuranceId: '',
    schedules: [],
    scheduleId: '',
    professionalId: '',
    search: '',
    hours: '',
  };
}

export default {
  show: false,
  accountShow: false,
  searchShow: false,
  patientAppointmentShow: false,
  printShow: false,
  commitmentShow: false,
  slotShow: false,
  schedules: [],
  professionals: [],
  insurances: [],
  items: [],
  loading: false,
  showFiles: false,
  showHistory: false,
  blankForm,
  form: blankForm(),
  currentMonth: null,
  available: {
    days: [],
    professionals: [],
    insurancePlans: [],
  },
  blankFilter,
  filter: blankFilter(),
  clipboard: {
    event: null,
    data: null,
  },
};
