export default (address, type = 'basic') => {
  if (!address) {
    return '';
  }

  if ('street' in address) {
    address.addressLine1 = `${address.street}, ${address.number}`;
  }
  if ('complement' in address) {
    address.addressLine2 = address.complement;
  }

  const parts = [address.addressLine1];

  if (address.addressLine2) {
    parts.push(' - ', address.addressLine2);
  }

  parts.push(' - ', address.neighborhood);

  if (type === 'full') {
    parts.push(' - ', address.city);
    parts.push(' - ', address.state);
  }

  return parts.join('');
};
