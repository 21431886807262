import moment from 'moment';
import { mergeFrom, deepClone } from 'src/helpers/object';

function findAppointment(state, data) {
  const main = state.items.find(({ key }) => key === data.key);
  if (main) {
    const refs = state.items.filter(({ refKey }) => refKey && refKey === data.key);
    return { main, refs };
  }
  return null;
}

function clearAppointment(state, data) {
  const found = findAppointment(state, data);
  if (found) {
    const { main, refs } = found;
    [main, ...refs].forEach((item) => {
      if (item.isTemp) {
        state.items.splice(state.items.indexOf(item), 1);
      } else {
        item.id = null;
        item.patient = null;
        item.insurance = null;
        item.status = 'available';
        item.expenses = [];
        item.refKey = null;
        item.notes = '';
        item.meta = null;
      }
    });
  }
}

function restorePreviousClipboardIntention(state) {
  if (state.clipboard.data !== null && state.clipboard.event === 'cut') {
    const original = findAppointment(state, state.clipboard.data);
    if (original) {
      Object.assign(original.main, state.clipboard.data);
    }
  }
}

function setProfessional(state) {
  if (state.available.professionals.length === 1) {
    const [professional] = state.available.professionals;
    if (professional.specialties.length === 1) {
      state.form.professional.id = professional.id;
      [state.form.professional.specialty] = professional.specialties;
    }
  }
}

export default {
  'Appointment.SET_ITEMS': function setItems(state, data) {
    state.items = data;
  },
  'Appointment.COPY_APPOINTMENT': function copyAppointment(state, data) {
    restorePreviousClipboardIntention(state);
    state.clipboard.event = 'copy';
    state.clipboard.data = deepClone(data);
    if (Array.isArray(state.clipboard.data.expenses)) {
      state.clipboard.data.expenses = state.clipboard.data.expenses.map((item) => {
        item.accountId = null;
        return item;
      });
    }
  },
  'Appointment.CUT_APPOINTMENT': function cutAppointment(state, data) {
    restorePreviousClipboardIntention(state);
    state.clipboard.event = 'cut';
    state.clipboard.data = deepClone(data);
    clearAppointment(state, data);
  },
  'Appointment.UPSERT': function upsert(state, data) {
    delete data.schedule;

    if ('originalKey' in data && data.originalKey) {
      clearAppointment(state, { ...data, key: data.originalKey });
    }

    if (state.filter.date !== data.startDate.substring(0, 10)) return;
    if (state.filter.scheduleId && state.filter.scheduleId !== data.scheduleId) return;
    if (state.filter.insuranceId && data.insurance) {
      if (!state.insurances.some(({ id }) => data.insurance.id === id)) return;
    }

    const found = findAppointment(state, data);
    if (found) {
      data.available.insurancePlans = found.main.available.insurancePlans;
      Object.assign(found.main, data);
    } else if (
      state.items.length === 0
      || state.items[state.items.length - 1].startDate < data.startDate
    ) {
      state.items.push(data);
    } else if (state.items[0].startDate > data.startDate) {
      state.items.unshift(data);
    } else {
      let idx = 0;

      state.items.forEach((item, i) => {
        if (data.startDate > item.startDate) {
          idx = i + 1;
        }
      });

      state.items.splice(idx, 0, data);
    }
  },
  'Appointment.REMOVE': function upsert(state, data) {
    clearAppointment(state, data);
  },
  'Appointment.SET_SCHEDULES': function setSchedules(state, data) {
    state.schedules = data;
  },
  'Appointment.SET_PROFESSIONALS': function setProfessionals(state, data) {
    state.professionals = data;
  },
  'Appointment.SET_INSURANCES': function setInsurances(state, data) {
    state.insurances = data;
  },
  'Appointment.UPDATE_AVAILABLE_DATA': function updateAvailableData(state) {
    const { scheduleId, id } = state.form;

    state.available.professionals = state.professionals
      .filter(({ schedules }) => schedules.includes(scheduleId))
      .map(item => ({
        ...item,
        specialties: item.specialties.map(({ code }) => code),
      }));
    state.available.insurancePlans = state.insurances
      .filter(({ schedules }) => schedules.includes(scheduleId))
      .map(({ plan }) => plan.id);

    if (!id) {
      const blankData = state.blankForm();
      state.form.professional = blankData.professional;
      state.form.insurance = blankData.insurance;
      setProfessional(state);
    }
  },
  'Appointment.SHOW_APPOINTMENT_MODAL': function showAppointmentModal(state, data = null) {
    if (data) {
      data = deepClone(data);
      data.expenses = data.expenses || [];
      state.available.professionals = data.available.professionals;
      state.available.insurancePlans = data.available.insurancePlans;
      state.form = mergeFrom(state.form, state.blankForm());
      state.form = mergeFrom(state.form, data);
      setProfessional(state);
      state.form.status = data.status === 'available' ? 'scheduled' : data.status;
      state.form.slot = data.slot || data.isSlot;
    } else {
      state.form = mergeFrom(state.form, state.blankForm());
      state.form.startDate = moment().format('YYYY-MM-DD[T]HH:mm');
      if (state.form.startDate.substring(0, 10) !== state.filter.date) {
        state.form.startDate = `${state.filter.date}T08:00`;
      }
      state.form.scheduleId = state.schedules.length > 0 ? state.schedules[0].id : null;
      state.form.slot = true;
      state.available.professionals = [];
      state.available.insurancePlans = [];
    }
    state.showFiles = false;
    state.showHistory = false;
    state.show = true;
  },
  'Appointment.HIDE_APPOINTMENT_MODAL': function hideAppointmentModal(state) {
    // eslint-disable-next-line
    state.show = false;
  },
  'Appointment.SET_APPOINTMENT_IN_USE_BY': function setAppointmentInUseBy(state, data) {
    if (state.form.key === data.key) {
      state.form.inUseBy = data.inUseBy;
    }

    const found = findAppointment(state, data);
    if (found) {
      found.main.inUseBy = data.inUseBy;
    }
  },
  'Appointment.SHOW_ACCOUNT_MODAL': function showAccountModal(state) {
    // eslint-disable-next-line
    state.accountShow = true;
  },
  'Appointment.HIDE_ACCOUNT_MODAL': function hideAccountModal(state) {
    // eslint-disable-next-line
    state.accountShow = false;
  },
  'Appointment.SHOW_SLOT_MODAL': function showSlotModal(state) {
    state.slotShow = true;
  },
  'Appointment.HIDE_SLOT_MODAL': function hideSlotModal(state) {
    state.slotShow = false;
  },
  'Appointment.SHOW_SEARCH_APPOINTMENT_MODAL': function showSearchAppointmentModal(state) {
    // eslint-disable-next-line
    state.searchShow = true;
  },
  'Appointment.HIDE_SEARCH_APPOINTMENT_MODAL': function hideSearchAppointmentModal(state) {
    // eslint-disable-next-line
    state.searchShow = false;
  },
  'Appointment.SHOW_PRINT_APPOINTMENT_MODAL': function showPrintAppointmentModal(state) {
    // eslint-disable-next-line
    state.printShow = true;
  },
  'Appointment.HIDE_PRINT_APPOINTMENT_MODAL': function hidePrintAppointmentModal(state) {
    // eslint-disable-next-line
    state.printShow = false;
  },
  'Appointment.SHOW_PATIENT_APPOINTMENT_MODAL': function showPatientAppointmentModal(state) {
    // eslint-disable-next-line
    state.patientAppointmentShow = true;
  },
  'Appointment.HIDE_PATIENT_APPOINTMENT_MODAL': function hidePatientAppointmentModal(state) {
    // eslint-disable-next-line
    state.patientAppointmentShow = false;
  },
  'Appointment.SHOW_COMMITMENT_APPOINTMENT_MODAL': function showCommitmentAppointmentModal(state) {
    // eslint-disable-next-line
    state.commitmentShow = true;
  },
  'Appointment.HIDE_COMMITMENT_APPOINTMENT_MODAL': function hideCommitmentAppointmentModal(state) {
    // eslint-disable-next-line
    state.commitmentShow = false;
  },
  'Appointment.SET_LOADING': function setLoading(state, value) {
    state.loading = value;
  },
  'Appointment.SET_APPOINTMENTS': function setAppointments(state, data) {
    state.appointments = data;
  },
  'Appointment.ADD_EXPENSE': function addExpense(state, data) {
    state.form.expenses.push(data);
  },
  'Appointment.DELETE_EXPENSE': function addDeleteExpense(state, data) {
    const account = state.form.expenses
      .find(item => item.id === data.id && item.accountId === null);
    if (account) {
      const index = state.form.expenses.indexOf(account);
      state.form.expenses.splice(index, 1);
    }
  },
  'Appointment.CLEAR_CLIPBOARD': function clearClipboard(state) {
    state.clipboard.event = null;
    state.clipboard.data = null;
  },
  'Appointment.SET_ACCOUNT': function setAccount(state, data) {
    const found = findAppointment(state, data);

    if (found) {
      found.main.expenses = data.expenses;
    }
  },
  'Appointment.SET_CURRENT_MONTH': function setCurrentMonth(state, date) {
    state.currentMonth = date;
  },
};
